<template>
<div>
  <!-- <v-row>
    <v-col>
      <v-select
        :items="fontSize"
        label="Standard"
        dense
        v-model="size"
      ></v-select>
    </v-col>
  </v-row> -->
  <v-sheet
    id="editor"
    :min-height="$vuetify.breakpoint.height / 4"
  >
  </v-sheet>
</div>
  <!-- <div id="editor" class="wh"></div> -->
</template>
<script>

// import { listen } from 'vscode-ws-jsonrpc'
// import { MonacoServices } from 'monaco-languageclient'
import loader from '@monaco-editor/loader'
window.setImmediate = window.setTimeout

export default {
  name: 'MonacoEditor',
  model: {
    prop: 'content'
  },
  props: {
    content: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
  }),
  mounted () {
    loader.init().then((monaco) => {
      const editorOptions = {
        language: 'javascript',
        minimap: { enabled: true },
        automaticLayout: true,
        glyphMargin: true,
        contextmenu: false,
        value: this.content.S
        // value: 'class Testcode:\n    def test(self):\n        print("Hello World")'
      }
      // let errors = jshint.JSHINT.data().errors.map(e => {
      //   return {
      //     startLineNumber: e.line,
      //     startColumn: e.character,
      //     endLineNumber: e.line,
      //     endColumn: e.character,
      //     message: e.raw,
      //     severity: e.code.startsWith('E') ? monaco.Severity.Error : monaco.Severity.Warning
      //   }
      // })
      // let mnc_2 = monaco.editor.setModelMarkers(monaco.editor.getModel(), 'test', errors)
      let mnc = monaco.editor.create(document.getElementById('editor'), editorOptions)
      mnc.addAction({
        id: 'my-unique-id',
        label: 'My Label!!!',
        keybindings: [
          monaco.KeyMod.Alt | monaco.KeyCode.KeyS,
          monaco.KeyMod.chord(
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM
          )
        ],
        precondition: null,
        keybindingContext: null,
        contextMenuGroupId: 'navigation',
        contextMenuOrder: 1.5,
        run: function (ed) {
          alert('im running => ' + ed.getPosition())
        }
      })
      mnc.onMouseLeave((e) => {
        this.$emit('input', { S: mnc.getValue() })
      })
      // monaco.editor.colorizeElement(document.getElementById('ceditorode'))
    })
  },
  computed: {
    height: function () {
      return this.$vuetify.breakpoint.height / 4
    },
    get: function () {
      return {}
    }
  },
  created () {
    // loader.init().then((monaco) => {
    //   const editorOptions = {
    //     language: 'python',
    //     minimap: { enabled: false },
    //     value: 'class Testcode:\n    def test(self):\n        print("Hello World")'
    //   }
    //   monaco.editor.create(document.getElementById('editor'), editorOptions)
    //   MonacoServices.install(monaco)
    //   // monaco.getModel().setValue('some value')
    //   // this.connectToLangServer()
    // })
  },
  methods: {
  },
  updated () {
    // this.$forceUpdate()
  }
  // methods: {
  //   createLanguageClient: function (connection) {
  //     return new MonacoLanguageClient({
  //       name: 'Monaco language client',
  //       clientOptions: {
  //         documentSelector: ['python'],
  //         errorHandler: {
  //           error: () => ErrorAction.Continue,
  //           closed: () => CloseAction.Restart
  //         }
  //       },
  //       connectionProvider: {
  //         get: (errorHandler, closeHandler) => {
  //           return Promise.resolve(
  //             createConnection(connection, errorHandler, closeHandler)
  //           )
  //         }
  //       }
  //     })
  //   },
  //   connectToLangServer: function () {
  //     const webSocket = new WebSocket('ws://127.0.0.1:8989')
  //     listen({
  //       webSocket: webSocket,
  //       onConnection: (connection) => {
  //         var languageClient = this.createLanguageClient(connection)
  //         var disposable = languageClient.start()
  //         connection.onClose(function () {
  //           return disposable.dispose()
  //         })
  //         connection.onError(function (error) {
  //           console.log(error)
  //         })
  //       }
  //     })
  //   }
  // }
}
</script>
<style>
.wh {
  width: 50%;
  height: 10%;
  position: absolute;
}
</style>
